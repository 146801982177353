@font-face {
  font-family: 'FormaleGrotesque';
  src: url('FormaleGrotesque-N1.woff') format('woff');
  /* 400まではnormalとしたいため範囲指定している */
  font-weight: 300 400;
  font-style: normal;
}

@font-face {
  font-family: 'FormaleGrotesque';
  src: url('FormaleGrotesque-N3.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'FormaleGrotesque';
  src: url('FormaleGrotesque-B1.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
