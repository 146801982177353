@font-face {
  font-family: 'NotoSansJP';
  src: url('NotoSansCJKjp-Regular.otf') format('opentype');
  /* 400まではnormalとしたいため範囲指定している */
  font-weight: 300 400;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSansJP';
  src: url('NotoSansCJKjp-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSansJP';
  src: url('NotoSansCJKjp-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
